import { Lang } from '@aimmo/i18n';

export interface EnvironmentConfig {
  phase: AimmoCorePhase;
  modelApiUrl: string;
  apiUrl: string;
  production: boolean;
  azureApiConfig?: {
    uri: string;
    scopes: string[];
  };
  googleClientId?: string;
  azureMsalConfig?: {
    auth: {
      clientId: string;
      authority: string;
    }
  };
  sentry: {
    dsn: string;
    release: string;
    environment: string;
  };
}

export enum AimmoCorePhase {
  local = 'local',
  dev = 'dev',
  qa = 'qa',
  beta = 'beta',
  prod = 'prod',
  localAzure = 'local-azure',
  devAzure = 'dev-azure',
  qaAzure = 'qa-azure',
  betaAzure = 'beta-azure',
  prodAzure = 'prod-azure',
}

export const SUPPORT_LANGUAGES = [Lang.KO, Lang.EN];
export const DEFAULT_LANGUAGE = Lang.EN;

// AimmoCore API
export const DEV_AIMMO_CORE_API_URL = 'https://dev-aimmo-core-api.bluewhale.team';
export const QA_AIMMO_CORE_API_URL = 'https://qa-aimmo-core-api.bluewhale.team';
export const BETA_AIMMO_CORE_API_URL = 'https://aimmo-core-api.bluewhale.team';
export const PROD_AIMMO_CORE_API_URL = BETA_AIMMO_CORE_API_URL;

// AimmoCore API for Azure
export const DEV_AZURE_AIMMO_CORE_API_URL = 'https://dev-aimmo-azure-api.bluewhale.team';
export const QA_AZURE_AIMMO_CORE_API_URL = 'https://qa-aimmo-azure-api.bluewhale.team';
export const BETA_AZURE_AIMMO_CORE_API_URL = 'https://aimmo-azure-api.bluewhale.team';
export const PROD_AZURE_AIMMO_CORE_API_URL = BETA_AZURE_AIMMO_CORE_API_URL;

// AimmoCore Model API
export const DEV_AIMMO_CORE_MODEL_API_URL = 'https://models-apis.bluewhale.kr/api';
export const QA_AIMMO_CORE_MODEL_API_URL = DEV_AIMMO_CORE_MODEL_API_URL;
export const PROD_AIMMO_CORE_MODEL_API_URL = 'https://models-apis.aimmo-mlops.com/api';

// AimmoCore Model API for Azure
export const DEV_AIMMO_CORE_AZURE_MODEL_API_URL = DEV_AIMMO_CORE_MODEL_API_URL;
export const QA_AIMMO_CORE_AZURE_MODEL_API_URL = QA_AIMMO_CORE_MODEL_API_URL;
export const PROD_AIMMO_CORE_AZURE_MODEL_API_URL = PROD_AIMMO_CORE_MODEL_API_URL;

// AimmoCore - google oauth client id
export const DEV_AIMMO_CORE_GOOGLE_OAUTH_CLIENT_ID = '840564308293-htnan8qsnjhc25dvg6qhgpudhj4qb56l.apps.googleusercontent.com';
export const PROD_AIMMO_CORE_GOOGLE_OAUTH_CLIENT_ID = '840564308293-54r4jqndl7ctgmnoi3le0pa61tofj6pb.apps.googleusercontent.com';

// AimmoCore for Azure - msal, api config
export const DEV_AIMMO_CORE_AZURE_MSAL_CONFIG = {
  auth: {
    // AIMMO Core - Smart Curation SSO for dev
    clientId: '51fd10f3-9c8a-4e58-be78-a7997ac43df4',
    authority: 'https://login.microsoftonline.com/common'
  }
};
export const DEV_AIMMO_CORE_AZURE_API_CONFIG = {
  uri: 'https://graph.microsoft.com/v1.0/me',
  scopes: ['user.read']
};
export const QA_AIMMO_CORE_AZURE_MSAL_CONFIG = DEV_AIMMO_CORE_AZURE_MSAL_CONFIG;
export const QA_AIMMO_CORE_AZURE_API_CONFIG = DEV_AIMMO_CORE_AZURE_API_CONFIG;

export const PROD_AIMMO_CORE_AZURE_MSAL_CONFIG = {
  auth: {
    // AIMMO Core - Smart Curation SSO
    clientId: '4d8cd5c2-5dac-461b-aadc-4227f8ee4b4a',
    authority: 'https://login.microsoftonline.com/common'
  }
};
export const PROD_AIMMO_CORE_AZURE_API_CONFIG = DEV_AIMMO_CORE_AZURE_API_CONFIG;

// 센트리 설정
export const AIMMO_CORE_SENTRY_DSN = 'https://2dc54c761184a6d3e65546e63b1ef47e@o219148.ingest.us.sentry.io/4507604683587585';

// AIMMO Core - 홈페이지 url
export const getAimmoCoreHomepageUrl = phase => {
  switch (phase) {
    case AimmoCorePhase.local:
      return 'https://dev.aimmo.ai/aimmo-core';
    case AimmoCorePhase.dev:
      return 'https://dev.aimmo.ai/aimmo-core';
    case AimmoCorePhase.qa:
      return 'https://qa.aimmo.ai/aimmo-core';
    case AimmoCorePhase.beta:
      return 'https://beta.aimmo.ai/aimmo-core';
    case AimmoCorePhase.prod:
      return 'https://aimmo.ai/aimmo-core';
    default:
      throw new Error('unknown phase');
  }
};

