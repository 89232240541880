import {
  AIMMO_CORE_SENTRY_DSN,
  AimmoCorePhase,
  DEV_AIMMO_CORE_GOOGLE_OAUTH_CLIENT_ID,
  EnvironmentConfig,
  QA_AIMMO_CORE_API_URL,
  QA_AIMMO_CORE_MODEL_API_URL
} from './environment.interface';

export const environment: EnvironmentConfig = {
  phase: AimmoCorePhase.qa,
  apiUrl: QA_AIMMO_CORE_API_URL,
  modelApiUrl: QA_AIMMO_CORE_MODEL_API_URL,
  googleClientId: DEV_AIMMO_CORE_GOOGLE_OAUTH_CLIENT_ID,
  production: true,
  sentry: {
    dsn: AIMMO_CORE_SENTRY_DSN,
    environment: AimmoCorePhase.qa,
    release: ''
  },
};
